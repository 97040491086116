<template>
  <section id="realisations" class="py-20 bg-black">
    <div class="container mx-auto px-4">
      <!-- En-tête de section -->
      <div class="text-center mb-16">
        <h2 class="text-4xl font-bold text-white mb-4">
          Mes <span class="text-gradient">Réalisations</span>
        </h2>
        <p class="text-gray-300 max-w-2xl mx-auto">
          Découvrez mes projets les plus récents. Chaque projet représente une
          opportunité d'apprentissage et un défi relevé avec passion.
        </p>
      </div>

      <!-- Filtres de projets -->
      <div class="mb-12 flex flex-wrap justify-center gap-4">
        <button
          v-for="categorie in categories"
          :key="categorie"
          @click="filtrerPar(categorie)"
          :class="[
            'px-5 py-2 rounded-full transition-all duration-300 filter-btn',
            categorieActive === categorie
              ? 'active-filter'
              : 'inactive-filter',
          ]"
        >
          {{ categorie }}
        </button>
      </div>

      <!-- Grille de projets -->
      <transition-group
        name="projet-transition"
        tag="div"
        class="masonry-grid"
      >
        <div
          v-for="projet in projetsFiltres"
          :key="projet.id"
          class="masonry-item mb-8"
        >
          <div class="projet-card overflow-hidden shadow-xl">
            <!-- Bannière de catégorie -->
            <div class="category-ribbon">{{ projet.categorie }}</div>
            
            <!-- Image du projet avec overlay -->
            <div class="projet-image-container">
              <img
                :src="projet.image"
                :alt="projet.titre"
                class="projet-img"
              />
              <div class="projet-overlay">
                <div class="overlay-actions">
                  <a
                    v-if="projet.demo"
                    :href="projet.demo"
                    target="_blank"
                    class="action-btn demo-btn"
                  >
                    <i class="fas fa-eye mr-2"></i>Voir le projet
                  </a>
                  <a
                    v-if="projet.code"
                    :href="projet.code"
                    target="_blank"
                    class="action-btn code-btn"
                  >
                    <i class="fab fa-github mr-2"></i>Voir le code
                  </a>
                </div>
              </div>
            </div>

            <!-- Contenu du projet -->
            <div class="projet-content">
              <h3 class="projet-title">{{ projet.titre }}</h3>
              
              <div class="mb-4">
                <p 
                  class="projet-description" 
                  :class="{ 'line-clamp-3': !projet.showFullDescription }"
                >
                  {{ projet.description }}
                </p>
                <button 
                  v-if="projet.description.length > 180" 
                  @click="toggleDescription(projet)"
                  class="read-more-btn"
                >
                  {{ projet.showFullDescription ? 'Voir moins' : 'Voir plus' }}
                  <i 
                    class="fas ml-1 text-xs" 
                    :class="[projet.showFullDescription ? 'fa-chevron-up' : 'fa-chevron-down']"
                  ></i>
                </button>
              </div>

              <!-- Technologies utilisées -->
              <div class="tech-badges">
                <span
                  v-for="(tech, index) in projet.technologies"
                  :key="index"
                  class="tech-badge"
                >
                  {{ tech }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </section>
</template>

<script>
export default {
  name: "RealisationsSection",
  data() {
    return {
      categorieActive: "Tous",
      categories: ["Tous", "Site Vitrine", "e-commerce", "Portfolio"],
      projets: [
        {
          id: 1,
          titre: "Nou Ka Vann",
          categorie: "e-commerce",
          description:
            "Nou Ka Vann est un site web de vente de plantes médicinales originaires des DOM-TOM. Entièrement réalisé en HTML et CSS. C'est mon premier site internet.",
          image: require("../assets/img/NKVBlack.png"), // Remplacer par une image existante
          technologies: ["HTML", "CSS", "JavaScript"],
          demo: "https://jade-chebakia-5b5dbd.netlify.app/",
          code: null,
          showFullDescription: false,
        },
        {
          id: 2,
          titre: "LuxBraces",
          categorie: "e-commerce",
          description:
            "Luxbraces est une boutique en ligne spécialisée dans la vente de bracelets en cuir pour hommes, réalisée avec Shopify. Ce site e-commerce offre une expérience utilisateur moderne et intuitive, permettant aux clients de découvrir et d'acheter facilement des bracelets en cuir de qualité.",
          image: require("../assets/img/Fichier 1.svg"), // Remplacer par une image existante
          technologies: ["Shopify"],
          demo: "https://luxbraces.com",
          code: null,
          showFullDescription: false,
        },
        {
          id: 3,
          titre: "Cabbie",
          categorie: "Site Vitrine",
          description:
            "Cabbie est une application que je développe actuellement avec mon associé pour répondre à un besoin : dans les DOM-TOM, il est souvent compliqué de trouver un taxi rapidement. L'application, en cours de développement avec React Native, permettra aux voyageurs de trouver un taxi rapidement et facilement. Par ailleurs, nous avons déjà conçu un site web en Vue.js pour accompagner l'application. Cependant, ce n'est pas notre conception finale, et des modifications seront encore apportées.",
          image: require("../assets/img/logo_cabbie.png"), // Utiliser l'image existante
          technologies: ["Vue.js"],
          demo: "https://moncabbie.fr",
          code: "https://github.com/username/fitness-app",
          showFullDescription: false,
        },
        {
          id: 4,
          titre: "Upgrade Computer",
          categorie: "e-commerce",
          description:
            "Lors de mon stage au sein de l'entreprise Upgrade Computer, j'ai participé à la conception du site internet de l'entreprise à l'aide du CMS WordPress. J'ai principalement été chargé d'ajouter l'ensemble des produits à la boutique.",
          image: require("../assets/img/UPCLogo.png"),
          technologies: ["WordPress", "WooCommerce"],
          demo: "https://upgradecomputer.fr",
          code: null,
          showFullDescription: false,
        },
        {
          id: 5,
          titre: "Portfolio",
          categorie: "Portfolio",
          description:
            "Mon portfolio est un projet personnel que j'ai réalisé pour présenter mes compétences et mes projets. J'ai conçu ce site web avec Vue.js et Tailwind CSS. J'ai également utilisé des animations et des transitions pour rendre le site plus interactif et attrayant.",
          image: require("../assets/img/LogoHRWBlanc.png"),
          technologies: ["Vue.js", "Tailwind CSS"],
          demo: "https://harrow.fr",
          code: "",
          showFullDescription: false,
        },
        {
          id: 6,
          titre: "Boutique Calypso",
          categorie: "e-commerce",
          description:
            "Dans le cadre de mon devoir en communication, j'ai créé un site internet pour la boutique Calypso. Spécialisée dans la vente de bikinis, de vêtements de resort et d'articles de décoration intérieure en provenance de Bali et du Maroc. Pour ce projet, je me suis appuyé sur le contenu et l'esthétique de leur page Instagram (@calypso.mq) afin de développer une plateforme web conviviale qui reflète l'identité visuelle de la boutique. Le site présente les produits disponibles, fournit des informations pratiques telles que les horaires d'ouverture et l'adresse, et intègre des fonctionnalités pour améliorer l'expérience utilisateur.",
          image: require("../assets/logo.png"),
          technologies: ["Vue.js", "Node.js", "Tailwind CSS"],
          demo: "https://v1calypso.netlify.app",
          code: null,
          showFullDescription: false,
        },
      ],
    };
  },
  computed: {
    projetsFiltres() {
      if (this.categorieActive === "Tous") {
        return this.projets;
      }
      return this.projets.filter(
        (projet) => projet.categorie === this.categorieActive
      );
    },
  },
  methods: {
    filtrerPar(categorie) {
      this.categorieActive = categorie;
    },
    toggleDescription(projet) {
      projet.showFullDescription = !projet.showFullDescription;
    }
  },
};
</script>

<style scoped>
.text-gradient {
  background: linear-gradient(to right, #ffd700, #ffa500);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

/* Filtres */
.filter-btn {
  font-weight: 500;
  border: 1px solid transparent;
  transform: translateY(0);
  box-shadow: 0 0 0 rgba(255, 215, 0, 0);
}

.active-filter {
  background: linear-gradient(to right, #ffd700, #ffa500);
  color: #1a1a1a;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(255, 215, 0, 0.4);
}

.inactive-filter {
  background: rgba(255, 255, 255, 0.1);
  color: #e2e8f0;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.inactive-filter:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-1px);
}

/* Grille Masonry */
.masonry-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5rem;
}

@media (min-width: 768px) {
  .masonry-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .masonry-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Cartes de projet */
.projet-card {
  position: relative;
  background: linear-gradient(145deg, #3a3d44, #4a4d54);
  border-radius: 16px;
  transition: all 0.4s ease;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.projet-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
  border-color: rgba(255, 215, 0, 0.2);
}

/* Ruban de catégorie */
.category-ribbon {
  position: absolute;
  top: 20px;
  right: -30px;
  background: linear-gradient(to right, #ffd700, #ffa500);
  color: #1a1a1a;
  padding: 5px 30px;
  font-size: 0.75rem;
  font-weight: 600;
  transform: rotate(45deg);
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

/* Image et overlay */
.projet-image-container {
  position: relative;
  height: 200px;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #2a2a2a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.projet-img {
  max-height: 85%;
  max-width: 85%;
  object-fit: contain;
  transition: transform 0.5s ease;
}

.projet-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.projet-card:hover .projet-img {
  transform: scale(1.05);
}

.projet-card:hover .projet-overlay {
  opacity: 1;
}

/* Actions d'overlay */
.overlay-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  transform: translateY(20px);
  transition: transform 0.3s ease;
}

.projet-card:hover .overlay-actions {
  transform: translateY(0);
}

.action-btn {
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.demo-btn {
  background: linear-gradient(to right, #ffd700, #ffa500);
  color: #1a1a1a;
}

.demo-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(255, 215, 0, 0.4);
}

.code-btn {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.code-btn:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Contenu du projet */
.projet-content {
  padding: 1.5rem;
}

.projet-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: white;
  margin-bottom: 0.75rem;
  background: linear-gradient(to right, #fff, #e2e2e2);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.projet-description {
  color: #d1d5db;
  font-size: 0.95rem;
  line-height: 1.6;
}

.read-more-btn {
  margin-top: 0.5rem;
  color: #ffd700;
  font-size: 0.85rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.read-more-btn:hover {
  color: #ffa500;
}

/* Badges de technologies */
.tech-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.tech-badge {
  font-size: 0.7rem;
  padding: 4px 8px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  color: #e2e8f0;
  font-weight: 500;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Animations de transition */
.projet-transition-enter-active,
.projet-transition-leave-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.projet-transition-enter-from,
.projet-transition-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.projet-transition-move {
  transition: transform 0.8s ease;
}
</style>
