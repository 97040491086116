<template>
  <div class="min-h-screen font-sans">
    <section id="accueil" class="hero relative overflow-hidden">
      <!-- Arrière-plan avec fond noir et particules -->
      <div class="absolute inset-0 bg-black"></div>
      <div class="particles-container absolute inset-0"></div>

      <!-- Grille géométrique en arrière-plan avec opacité réduite -->
      <div class="absolute inset-0 grid-pattern opacity-20"></div>

      <div
        class="container mx-auto px-4 relative z-10 h-full flex flex-col justify-center mt-16"
      >
        <div
          class="flex flex-col md:flex-row items-center justify-between mt-10"
        >
          <!-- Partie gauche - Texte et CTA -->
          <div class="text-left md:w-1/2 mb-10 md:mb-0 order-2 md:order-1">
            <h1
              class="text-4xl sm:text-5xl lg:text-6xl font-extrabold mb-4 text-white tracking-tight hero-title mt-4"
            >
              <span class="block">Jean-Michel</span>
              <span class="text-gradient">Harrow</span>
            </h1>

            <div class="typewriter-container relative h-12 my-4">
              <p class="text-xl text-gray-200 typewriter absolute">
                <span class="typing"></span>
              </p>
            </div>

            <p class="text-lg mb-8 text-gray-300 max-w-lg">
              Je vous invite à découvrir mes passions à travers les pages de ce
              site. Découvrez mes projets, aventures et compétences, ainsi un
              aperçu détaillé de mon parcours professionnel dans la section
              Curriculum Vitae. Merci de partager ce voyage avec moi.
            </p>

            <div class="flex flex-wrap gap-4">
              <a href="#realisations" class="btn-primary">
                <span class="relative z-10">Découvrir mes projets</span>
              </a>
              <a href="#contact" class="btn-secondary">
                <span>Me contacter</span>
              </a>
            </div>
          </div>

          <!-- Partie droite - Photo de profil -->
          <div
            class="profile-container order-1 md:order-2 md:w-1/2 flex justify-center md:justify-end mb-8 md:mb-0"
          >
            <div class="profile-frame">
              <!-- Remplacez par votre photo ou une image vectorielle -->
              <div class="profile-image-placeholder">
                <img src="../assets/img/subject1.png" alt="" srcset="" />
              </div>
            </div>
          </div>
        </div>

        <!-- Technologies maîtrisées -->
        <div class="tech-badges mt-12 md:mt-16 w-full">
          <h3 class="text-gray-300 mb-6 text-center text-xl font-semibold">
            Technologies maîtrisées
          </h3>
          <div class="tech-scroll-container">
            <div class="tech-scroll">
              <div class="tech-slider mt-20">
                <!-- Premier groupe de badges -->
                <div class="tech-group">
                  <div class="tech-badge">
                    <i class="fab fa-html5 text-orange-500"></i>
                    <span>HTML5</span>
                  </div>
                  <div class="tech-badge">
                    <i class="fab fa-css3-alt text-blue-500"></i>
                    <span>CSS3</span>
                  </div>
                  <div class="tech-badge">
                    <i class="fab fa-js text-yellow-400"></i>
                    <span>JavaScript</span>
                  </div>
                  <div class="tech-badge">
                    <i class="fab fa-vuejs text-green-500"></i>
                    <span>Vue.js</span>
                  </div>
                  <div class="tech-badge">
                    <i class="fab fa-react text-blue-400"></i>
                    <span>React Native</span>
                  </div>
                  <div class="tech-badge">
                    <i class="fab fa-node-js text-green-600"></i>
                    <span>Node.js</span>
                  </div>
                  <div class="tech-badge">
                    <i class="fab fa-php text-indigo-500"></i> <span>PHP</span>
                  </div>
                  <div class="tech-badge">
                    <i class="fab fa-git-alt text-red-500"></i> <span>Git</span>
                  </div>
                </div>

                <!-- Duplication pour une animation continue -->
                <div class="tech-group">
                  <div class="tech-badge">
                    <i class="fab fa-html5 text-orange-500"></i>
                    <span>HTML5</span>
                  </div>
                  <div class="tech-badge">
                    <i class="fab fa-css3-alt text-blue-500"></i>
                    <span>CSS3</span>
                  </div>
                  <div class="tech-badge">
                    <i class="fab fa-js text-yellow-400"></i>
                    <span>JavaScript</span>
                  </div>
                  <div class="tech-badge">
                    <i class="fab fa-vuejs text-green-500"></i>
                    <span>Vue.js</span>
                  </div>
                  <div class="tech-badge">
                    <i class="fab fa-react text-blue-400"></i>
                    <span>React Native</span>
                  </div>
                  <div class="tech-badge">
                    <i class="fab fa-node-js text-green-600"></i>
                    <span>Node.js</span>
                  </div>
                  <div class="tech-badge">
                    <i class="fab fa-php text-indigo-500"></i> <span>PHP</span>
                  </div>
                  <div class="tech-badge">
                    <i class="fab fa-git-alt text-red-500"></i> <span>Git</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Indicateur de défilement -->
        <!-- <div
          class="absolute bottom-20 left-1/2 transform -translate-x-1/2 scroll-indicator"
        >
          <p class="text-gray-400 text-sm mb-2">Découvrir</p>
          <svg
            class="w-6 h-6 text-yellow-400 animate-bounce mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 14l-7 7m0 0l-7-7m7 7V3"
            ></path>
          </svg>
        </div> -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "HeroPage",
  components: {},
  mounted() {
    this.initTypewriter();
    this.createParticles();
  },
  methods: {
    initTypewriter() {
      const texts = [
        "Etudiant en BUT Informatique",
        "Passionné par le développement web",
        "Développeur Full Stack",
        "Créateur d'expériences numériques",
      ];
      let textIndex = 0;
      let charIndex = 0;
      let isDeleting = false;
      const typingElement = document.querySelector(".typing");

      const type = () => {
        const currentText = texts[textIndex];
        if (isDeleting) {
          typingElement.textContent = currentText.substring(0, charIndex - 1);
          charIndex--;
        } else {
          typingElement.textContent = currentText.substring(0, charIndex + 1);
          charIndex++;
        }

        if (!isDeleting && charIndex === currentText.length) {
          isDeleting = true;
          setTimeout(type, 1500);
        } else if (isDeleting && charIndex === 0) {
          isDeleting = false;
          textIndex = (textIndex + 1) % texts.length;
          setTimeout(type, 500);
        } else {
          setTimeout(type, isDeleting ? 50 : 100);
        }
      };

      setTimeout(type, 1000);
    },
    createParticles() {
      const container = document.querySelector(".particles-container");
      const particleCount = 50;

      for (let i = 0; i < particleCount; i++) {
        const particle = document.createElement("div");
        particle.classList.add("particle");

        // Position aléatoire
        const x = Math.random() * 100;
        const y = Math.random() * 100;
        const size = Math.random() * 4 + 1;
        const duration = Math.random() * 20 + 10;
        const delay = Math.random() * 5;

        particle.style.left = `${x}%`;
        particle.style.top = `${y}%`;
        particle.style.width = `${size}px`;
        particle.style.height = `${size}px`;
        particle.style.animationDuration = `${duration}s`;
        particle.style.animationDelay = `${delay}s`;

        container.appendChild(particle);
      }
    },
  },
};
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}

.hero {
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}

/* Titre avec dégradé */
.text-gradient {
  background: linear-gradient(to right, #ffd700, #ffa500);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

/* Animation du titre */
.hero-title {
  opacity: 0;
  animation: fadeInUp 1s ease-out forwards;
}

/* Particules d'arrière-plan - couleur ajustée pour fond noir */
.particle {
  position: absolute;
  background-color: rgba(252, 252, 252, 0.2);
  border-radius: 50%;
  animation: float infinite ease-in-out;
}

.grid-pattern {
  background-image: radial-gradient(
    rgba(255, 255, 255, 0.08) 1px,
    transparent 1px
  );
  background-size: 30px 30px;
}

/* Conteneur de photo de profil */
.profile-container {
  animation: fadeIn 1.2s ease-out;
}

.profile-frame {
  width: 425px;
  height: 425px;
  right: 50px 100px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  overflow: hidden;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  transform: rotate(-3deg);
}

.profile-image-placeholder {
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #000000, #ffa500);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  color: rgba(255, 255, 255, 0.2);
}

/* Boutons stylisés */
.btn-primary {
  display: inline-block;
  padding: 1rem 2rem;
  font-weight: 600;
  position: relative;
  background: linear-gradient(to right, #ffd700, #ffa500);
  color: #1e3a8a;
  border-radius: 30px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 10px 15px -3px rgba(255, 193, 7, 0.3);
}

.btn-primary:hover {
  transform: translateY(-3px);
  box-shadow: 0 15px 20px -3px rgba(255, 193, 7, 0.4);
}

.btn-primary:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.btn-primary:hover:before {
  left: 100%;
}

.btn-secondary {
  display: inline-block;
  padding: 1rem 2rem;
  font-weight: 600;
  background: transparent;
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  transition: all 0.3s ease;
}

.btn-secondary:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.6);
  transform: translateY(-3px);
}

/* Badges de technologies - version améliorée */
.tech-badge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(8px);
  padding: 0.75rem 1.25rem;
  border-radius: 50px;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 100px;
  justify-content: center;
  margin: 0 0.75rem;
}

.tech-badge i {
  font-size: 1.4rem;
}

.tech-badge:hover {
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-3px) scale(1.05);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

/* Animation de défilement plein écran */
.tech-scroll-container {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden;
  padding: 1rem 0;
  background: rgba(0, 0, 0, 0.2);
}

.tech-scroll {
  overflow: hidden;
  width: 100%;
}

.tech-slider {
  display: flex;
  animation: infiniteScroll 30s linear infinite;
  width: fit-content;
}

.tech-group {
  display: flex;
  flex-shrink: 0;
  padding: 0 1rem;
}

@keyframes infiniteScroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .tech-badge {
    padding: 0.5rem 1rem;
    min-width: 80px;
    font-size: 0.9rem;
  }

  .tech-badge i {
    font-size: 1.2rem;
  }

  .tech-scroll-container {
    padding: 0.5rem 0;
  }

  .profile-frame {
    width: 280px;
    height: 280px;
    margin-bottom: 2rem;
  }

  .tech-badges {
    margin-top: 2rem;
  }
}

/* Animation de l'écriture */
.typewriter-container {
  overflow: hidden;
}

.typewriter {
  border-right: 3px solid #ffd700;
  white-space: nowrap;
  overflow: hidden;
}

/* Scroll et animations */
.tech-scroll {
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  max-width: 90%;
}

.animate-scroll {
  display: inline-block;
  animation: scroll 30s linear infinite;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
</style>
